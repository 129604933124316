/* eslint-disable */
const selectProvince = (postalcode, locale) => {
  if(postalcode.charAt(0).toUpperCase() == 'A'){
    //Newfoundland
    return {value:'NF', label: locale == 'fr' ?  'Labrador':'Newfoundland and Labrador'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'B'){
    //NS
    return {value:'NS', label: locale == 'fr' ?  'Nouvelle-écosse':'Nova Scotia'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'C'){
    //PEI
    return {value:'PE', label: locale == 'fr' ? 'Île-du-Prince-Édouard':'Prince Edward Island'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'E'){
    //NB
    return {value:'NB', label: locale == 'fr' ? 'Nouveau-Brunswick':'New Brunswick'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'H' || postalcode.charAt(0).toUpperCase() == 'G' || postalcode.charAt(0).toUpperCase() == 'J'){
    //QC
    return {value:'QC', label: locale == 'fr' ?  'Québec':'Quebec'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'K' || postalcode.charAt(0).toUpperCase() == 'L' || postalcode.charAt(0).toUpperCase() == 'M' || postalcode.charAt(0).toUpperCase() == 'N' || postalcode.charAt(0).toUpperCase() == 'P'){
    //ON
    return {value:'ON', label:'Ontario'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'R'){
    //Manitoba
    return {value:'MB', label:'Manitoba'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'S'){
    //SK
    return {value:'SK', label:'Saskatchewan'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'T'){
    //AB
    return {value:'AB', label:'Alberta'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'V'){
    //BC
    return {value:'BC', label: locale == 'fr' ?  'Colombie-Britannique':'British-Columbia'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'X'){
    //Northwest
    return {value:'NT', label: locale == 'fr' ?  'Territoires du Nord-Ouest':'Northwest Territories'}
  }
  if(postalcode.charAt(0).toUpperCase() == 'Y'){
    //Yukon
    return {value:'YU', label: locale == 'fr' ?  'Yukon':'Yukon Territory'}
  }
}

export {selectProvince}
