/* eslint-disable */
import fetch from 'unfetch';
import LogError from '../helpers/Log'

//declare an ID for the contest
const contestId = 'faba9964-f185-403e-933e-68d1e4711630'

// Declare the URL of the graphql server
//const graphQlServer = 'http://sbackend.test/graphql'
const graphQlServer = 'https://api.royalepromotions.ca/graphql'

const customerExists = (email, callback) => {
  const query = JSON.stringify({
    query: `
      query($email: String, $contest_id: String!) {
        customers(email: $email) {
          uuid
          roundtable_member
          today_total_contest_entries(contest_id: $contest_id)
          total_contest_entries(contest_id: $contest_id)
        }
      }
    `,
    variables: {
      email: email,
      contest_id: contestId
    }
  });

  fetch(graphQlServer, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: query
  })
    .then(res => res.json())
    .then(res => {
      const data = res.data
      if (data.customers[0] && data.customers[0].uuid) {
        // The customer exist, pass true & the customer's info
        callback(true,data.customers[0])
      } else {
        // The customer does not exist
        callback(false,null)
      }
    }).catch((error) => {
        console.log(error)
      LogError('ErrorCode #1: customerExists()', 'customerExists', error, {
        'state': {
          email: email,
          contest_id: contestId
        }
      })
      alert('Something went wrong (ErrorCode #1: customerExists)')
    })
}

const createCustomer = (appState) => {
  const query = JSON.stringify({
    query: `
      mutation (
      $contest_id: String!
      $first_name: String!
      $last_name: String!
      $postal_code: String!
      $address1: String
      $address2: String
      $city: String
      $province: String
      $phone: String
      $birth_year: Int!
      $email: String!
      $locale: String!
      $subscribe: Boolean
    ) {
      createCustomer(
        contest_id: $contest_id
        first_name: $first_name
        last_name: $last_name
        postal_code: $postal_code
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        phone: $phone
        birth_year: $birth_year
        email: $email
        locale: $locale
        subscribe: $subscribe
      ) {
        uuid
      }
    }
    `,
    variables: {
      contest_id: contestId,
      first_name: appState.customer_firstname,
      last_name: appState.customer_lastname,
      postal_code: appState.customer_zipcode,
      address1: appState.customer_address,
      address2: appState.customer_address2,
      city: appState.customer_city,
      province: appState.customer_state,
      phone: appState.customer_phone,
      birth_year: appState.customer_yearofbirth,
      email: appState.customer_email,
      locale: appState.locale,
      subscribe: appState.customer_newsletter
    }
  });

  fetch(graphQlServer, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: query
  })
    .then(res => res.json())
    .then(res => {
      const data = res.data
      createContestEntry(data.createCustomer.uuid,appState)
    }).catch((error) => {
      LogError('ErrorCode #2: createCustomer()', 'createCustomer', error, {
        'state': appState
      })
      alert('Something went wrong (ErrorCode #2: createCustomer)')
    })

}

const updateCustomer = (appState) => {
  const query = JSON.stringify({
    query: `
      mutation (
      $contest_id: String!
      $uuid: String!
      $first_name: String
      $last_name: String
      $postal_code: String
      $address1: String
      $address2: String
      $city: String
      $province: String
      $phone: String
      $birth_year: Int
      $subscribe: Boolean
      $locale: String!
    ) {
      updateCustomer(
        contest_id: $contest_id
        uuid: $uuid
        first_name: $first_name
        last_name: $last_name
        postal_code: $postal_code
        address1: $address1
        address2: $address2
        city: $city
        province: $province
        phone: $phone
        birth_year: $birth_year
        subscribe: $subscribe
        locale: $locale
      ) {
        uuid
      }
    }
    `,
    variables: {
      contest_id: contestId,
      uuid: appState.uuid,
      first_name: appState.customer_firstname,
      last_name: appState.customer_lastname,
      postal_code: appState.customer_zipcode,
      address1: appState.customer_address,
      address2: appState.customer_address2,
      city: appState.customer_city,
      province: appState.customer_state,
      phone: appState.customer_phone,
      birth_year: appState.customer_yearofbirth,
      subscribe: true,
      locale: appState.locale
    }
  });

  fetch(graphQlServer, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: query
  })
    .then(res => res.json())
    .then(res => {
      const data = res.data
      createContestEntry(appState.uuid,appState)
    }).catch((error) => {
        console.log(error)
          LogError('ErrorCode #3: updateCustomer()', 'updateCustomer', error, {
            'state': appState
          })
      alert('Something went wrong (ErrorCode #3: updateCustomer)')
    })
}

function createContestEntry(uuid,appState){
  const query = JSON.stringify({
    query: `
      mutation (
      $contest_id: String!
      $customer_id: String!
      $completed: Boolean!
      $type: String!
      $step: Int!
      $data: String
    ) {
      createEntry(
        contest_id: $contest_id
        customer_id: $customer_id
        completed: $completed
        type: $type
        step: $step
        data: $data
      ) {
        uuid
      }
    }
    `,
    variables: {
      contest_id: contestId,
      customer_id: uuid,
      completed: true,
      type: 'contest',
      step: 3,
      data: JSON.stringify(appState)
    }
  });

  fetch(graphQlServer, {
    headers: {'content-type': 'application/json'},
    method: 'POST',
    body: query
  })
    .then(res => res.json())
    .then(res => {
      res = res.data.createEntry
      sessionStorage.setItem('customer_uuid',res.uuid);
    }).catch((error) => {
      LogError('ErrorCode #4: createContestEntry()', 'createContestEntry', error, {
        'state': appState
      })
      alert('Something went wrong (ErrorCode #4: createContestEntry)')
    })
}


export { customerExists, createContestEntry, createCustomer, updateCustomer }
