/* eslint-disable */
function LogError(error, message, extra) {
  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    // TODO: Error reporting
  }

  if (process.env.GATSBY_ACTIVE_ENV !== 'production') {
    console.log(`%ERROR`, 'background: #cc1f1a; color: white; display: block; text-align: center; font-weight: bold; width: 100%;');
    console.log(message);
    console.log(error);
    if (typeof extra === 'object' && extra !== null) {
      console.table(extra);
    } else {
      console.log(extra);
    }
  }
}

export default LogError;
