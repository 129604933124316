/* eslint-disable */
import createDataContext from './createDataContext';
import { customerExists, createContestEntry, createCustomer,updateCustomer } from '../api/contestApi';
import { selectProvince } from '../helpers/Province';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-57800739-1');

const entryReducer = (state, action) => {
    switch (action.type) {
        case 'show_modal_screen':
            return {
                ...state,
                customer_email: action.payload.email,
                customer_terms: action.payload.terms,
                modal:true,
                customer_alreadyEntered:false,
                uuid:action.payload.uuid,
                isLoading:false
            }
        case 'close_modal_screen':
            return {
                ...state,
                modal:action.payload
            }
        case 'go_back':
            return {
                ...state,
                modalStep:action.payload
            }
        case 'customer_already_entered':
            return {
                ...state,
                customer_alreadyEntered: action.payload
            }
        case 'process_step_1':
            return {
                ...state,
                modalStep: 2,
                customer_firstname: action.payload.firstname,
                customer_lastname: action.payload.lastname,
                customer_zipcode: action.payload.postal_code,
                customer_yearofbirth: action.payload.year_of_birth,
                customer_phone: action.payload.phone,
                customer_newsletter:action.payload.newsletter,
                customer_state:action.payload.state,
            }
        case 'process_step_2':
            let finalState = {
                ...state,
                isContestComplete:true,
                modal: false,
                customer_address: action.payload.address,
                customer_address2: action.payload.address2,
                customer_city: action.payload.city,
                customer_state: action.payload.state
            }
            if(action.payload.uuid){
                updateCustomer(finalState)
            } else {
                createCustomer(finalState)
            }

            return finalState
        case 'set_loading':
            return {
                ...state,
                isLoading: action.payload
            }
        case 'update_customer_uuid':
            return {
                ...state,
                roundtable_member: action.payload.roundtable_member,
                today_total_contest_entries:action.payload.today_total_contest_entries,
                total_contest_entries:action.payload.total_contest_entries,
                uuid:action.payload.uuid,
                customer_newsletter:true
            }
        case 'create_contest_entry':
            let returnState = {
                ...state,
                isContestComplete: true,
                customer_total_entries:action.payload.totalEntries,
                customer_roundtable:action.payload.roundtable,
            }
            createContestEntry(action.payload.uuid, returnState)
            return returnState
        default:
          return state
    }
};

const startEntryProcess = dispatch => async (data) => {
    dispatch({
        type:'set_loading',
        payload:true
    })
    customerExists(data.email,(validCustomer,details) => {
        if(validCustomer){
            dispatch({
                type:'update_customer_uuid',
                payload:{
                    roundtable_member: details.roundtable_member,
                    today_total_contest_entries:details.today_total_contest_entries,
                    total_contest_entries:details.total_contest_entries,
                    uuid:details.uuid,

                }
            })
            if(details.today_total_contest_entries > 0){
                dispatch({
                    type: 'customer_already_entered',
                    payload: true
                })
            } else if (details.total_contest_entries > 0) {
                ReactGA.pageview('/gufs-crm-repeat-complete');
                dispatch({
                    type: 'create_contest_entry',
                    payload: {
                        uuid: details.uuid,
                        totalEntries: details.total_contest_entries,
                        roundtable: details.roundtable_member
                    }
                })
            } else {
                ReactGA.pageview('/gufs-crm-modal-1');
                dispatch({
                    type: 'show_modal_screen',
                    payload: {
                        email:data.email,
                        terms:data.legalTerms,
                        uuid:details.uuid
                    }
                })
            }
        } else {
            ReactGA.pageview('/gufs-new-modal-1');
            dispatch({
                type: 'show_modal_screen',
                payload: {
                    email:data.email,
                    terms:data.legalTerms,
                    uuid:false
                }
            })
        }
    })
}

const nextStep = dispatch => (data,currentStep,customerUuid) => {
    if(currentStep === 1){
        if(customerUuid){
          ReactGA.pageview('/gufs-crm-modal-2');
        } else {
          ReactGA.pageview('/gufs-new-modal-2');
        }
        dispatch({
            type: 'process_step_1',
            payload: {
                newsletter:data.newsletter,
                firstname:data.firstname,
                lastname:data.lastname,
                postal_code:data.postal_code,
                year_of_birth:data.year_of_birth,
                phone:data.phone,
                state:selectProvince(data.postal_code,'en')
            }
        })



    }
    if(currentStep === 2){
        if(customerUuid){
          ReactGA.pageview('/gufs-crm-thank-you');
        } else {
          ReactGA.pageview('/gufs-new-thank-you');
        }

        dispatch({
            type: 'process_step_2',
            payload: {
                address:data.address,
                address2:data.address2,
                city:data.city,
                state:data.province,
                uuid:customerUuid
            }
        })

    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}

const goBack = dispatch => (currentStep) => {
    dispatch({
        type: 'go_back',
        payload: currentStep - 1
    })
}


const closeModal = dispatch => () => {
    dispatch({
        type: 'close_modal_screen',
        payload: false
    })
}

const isFunction = (functionToCheck) => {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const { Provider, Context } = createDataContext(
    // the reducer
        entryReducer,
    // functions to pass to context
    {
        startEntryProcess,
        closeModal,
        nextStep,
        goBack
    },
    // the state
    {
        locale: 'en',
        isLoading: false,
        modal: false,
        modalStep: 1,
        isContestComplete: false,
        customer_email: '',
        customer_firstname: '',
        customer_lastname: '',
        customer_address: '',
        customer_address2: '',
        customer_phone: '',
        customer_city: '',
        customer_state: '',
        customer_zipcode: '',
        customer_yearofbirth: '',
        customer_uuid: null,
        customer_alreadyEntered:false,
        customer_total_entries:null,
        customer_roundtable:false,
        customer_terms:false,
        customer_newsletter:false,
        roundtable_member:false,
        today_total_contest_entries:null,
        total_contest_entries:null,
        uuid:false
    }
);
