/* eslint-disable */
import React, { useState, useEffect  } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { When } from 'react-if';

import Header from '../Header'
import PetsHeader from '../../theme/PetsHeader'
import Container from '../../common/Container'

const HomeHeadText = styled.h1`
  margin: 3rem 0 -4rem 0;
  text-align: center;
  font-family: "Raleway", "Lucida Grande", Tahoma, sans-serif;
  font-weight: 200;
  font-size: 2.5rem;
  p {
    margin: 0;
  }
  @media screen and (max-width: 1366px) {
    margin: 4rem 0 0;
  }
`


const HomeHeader = ({locale, urls, frontHeaderText}) => {
  return (
    <Header locale={locale} urls={urls}>
      <PetsHeader locale={locale} />

      <Container>
        <HomeHeadText dangerouslySetInnerHTML={{ __html: frontHeaderText}} />
      </Container>
    </Header>
  )
}

HomeHeader.propTypes = {
  // Text that shows at the top
  frontHeaderText: PropTypes.string,
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired
  }),
  // used to determine current langage
  locale: PropTypes.string
}

HomeHeader.defaultProps = {
  frontHeaderText: '',
  switchPath: '',
  locale: 'en'
}

export default HomeHeader
